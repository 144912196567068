@tailwind base;
@tailwind components;
@tailwind utilities;

.video-container {
  position: relative;
  overflow: hidden;
  padding-top: 56.25%; /* Untuk menjaga rasio aspek video 16:9 */
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.link {
  @apply p-2.5 flex rounded-md gap-6 items-center md:cursor-pointer cursor-default duration-300 font-medium text-gray-700;
}

.active {
  @apply bg-blue-100 text-blue-600;
}

.ck.ck-content ul,
.ck.ck-content ul li {
  list-style-type: inherit;
}
.ck.ck-content ul {
  padding-left: 40px;
}

.ck.ck-content ol,
.ck.ck-content ol li {
  list-style-type: decimal;
}
.ck.ck-content ol {
  padding-left: 40px;
}
.ck-editor__editable_inline {
  padding: 0 30px !important;
}

.ck-editor__editable_inline {
  min-height: 300px;
}
